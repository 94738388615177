<template>
    <div class="fixed w-screen flex z-40 mt-10">
        <span class="w-1/2 mx-auto">
            <NotificationMessage v-for="notification in notifications" :key="notification.id" :show="true" :notification="notification"/>
        </span>
    </div>
</template>

<script>
import NotificationMessage from './NotificationMessage'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    components: {
        NotificationMessage,
    },
    setup() {
        const store = useStore()
        const notifications = computed(() => {
            return store.state.notify['notifications']
        })
        return {
            notifications
        }
    }
}
</script>

<style>
@import '@/assets/css/transitions.css';
</style>