import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/views/LandingPageView.vue'
import Dashboard from '@/views/DashboardView.vue'
import GenericalView from '@/views/GenericalView.vue'
import ForbiddenView from '@/views/ForbiddenView.vue'
import ConfirmacaoEmail from '@/views/ConfirmacaoEmail.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'home',
        component: LandingPage,
        meta: {
            anonymousOnly: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { 
            requiresAuth: true,
            permission: ['Admin', 'Gerente', 'Informante'],
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import('@/components/Dashboard/DashboardComp.vue'),
                meta: { permission: ['Admin', 'Gerente', 'Informante'] },
            },
            {
                path: 'bairros',
                name: 'BairrosView',
                component: GenericalView,
                meta: { permission: ['Admin'] },
                children: [
                    {path: '', component: () => import('@/components/Bairros/BairrosList.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
                    {path: 'novo', component: () => import('@/components/Bairros/CreateBairro.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
                ]
            },
            {
                path: 'usuarios',
                name: 'UsuariosView',
                component: GenericalView,
                meta: { permission: ['Admin', 'Gerente'] },
                children: [
                    {path: '', component: () => import('@/components/Usuarios/UsuariosList.vue'), meta: {requiresAuth: true, permission: ['Admin', 'Gerente']}},
                    {name: 'UsuarioProfile', path: 'perfil/:id', component: () => import('@/views/PerfilUsuarioView.vue'), meta: {requiresAuth: true, permission: ['Admin', 'Gerente', 'Informante']}},
                ]
            },
            {
                path: 'empresas',
                name: 'EmpresasView',
                component: GenericalView,
                meta: { permission: ['Admin', 'Gerente'] },
                children: [
                    {path: '', component: () => import('@/components/Empresas/EmpresasList.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
                    {path: 'nova', component: () => import('@/components/Empresas/CreateEmpresa.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
                ]
            },
            {
                path: 'empreendimentos/formularios',
                name: 'FormulariosView',
                component: GenericalView,
                meta: { permission: ['Admin', 'Gerente', 'Informante'] },
                children: [
                    {path: '', component: () => import('@/components/Formularios/FormulariosList.vue'), meta: {requiresAuth: true, permission: ['Admin', 'Gerente', 'Informante']}},
                    {path: 'novo', component: () => import('@/components/Formularios/CreateFormulario.vue'), meta: {requiresAuth: true, permission: ['Admin', 'Gerente', 'Informante']}},
                ]
            },
            {path: 'auditoria', component: () => import('../components/Auditoria/Auditoria.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
            {path: 'exportar/relatorios', component: () => import('../components/Relatorios/Relatorios.vue'), meta: {requiresAuth: true, permission: ['Admin']}},
            {path: 'empregados', component: () => import('@/components/Funcionarios/FuncionariosView.vue'), meta: {requiresAuth: true, permission: ['Admin', 'Gerente']}},
        ]
    },
    {
        path: '/auth/nao/autorizado',
        name: 'Forbidden',
        component: ForbiddenView,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: '/recuperar/senha',
        name: 'Reset',
        component: ResetPassword,
        meta: {
            anonymousOnly: true
        }
    },
    {
        path: '/successful',
        name: 'ConfirmacaoBemSucedida',
        component: ConfirmacaoEmail,
        meta: {
            anonymousOnly: true
        }
    },
    // {
    //     path: '/:catchAll(.*)',
    //     component: () => import('../views/NotFound.vue'),
    // }
]


  

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

const requiresAuthGuard = (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters['auth/authenticated']) {
            if (to.meta.permission.includes(store.getters['auth/user'].role)) {
                next()
            }else {
                next('/auth/nao/autorizado')
            }
        } else {
            next('/')
        }
        return true
    }
    return false
}

const anonymousOnlyGuard = (to, from, next) => {
    if (to.matched.some((record) => record.meta.anonymousOnly)) {
        if (store.getters['auth/authenticated']) {
            next('/dashboard')
        } else {
            next()
        }
        return true
    }
    return false
}

router.beforeEach(async (to, from, next) => {
    if (requiresAuthGuard(to, from, next)) {
        return
    }
    if (anonymousOnlyGuard(to, from, next)) {
        return
    }
    next() // make sure to always call next()!
})

export default router
