import AES from 'crypto-js/aes'
import Crypto from 'crypto-js'

export default function useEncryption() {
    const encrypt = (data) => {
        return AES.encrypt(data, process.env.VUE_APP_ENCRYPT_USER).toString()
    }
    const decrypt = (data) => {
        return AES.decrypt(data, process.env.VUE_APP_ENCRYPT_USER).toString(Crypto.enc.Utf8)
    }

    return { encrypt, decrypt }
}