<template>
    <h1 class="text-2xl text-neutral-500 text-center">Entrar</h1>
    <form class="w-4/5 mx-auto flex flex-col gap-y-8 mt-10" novalidate @submit.prevent="register()">
        <div class="w-full">
            <Input label="Nome" placeholder="Nome completo" type="text" v-model="state.name" :validation="v$.name"/>
        </div>
        <div>
            <Input label="Email" placeholder="Email" type="email" v-model="state.email" :validation="v$.email"/>
        </div>
        <div class="w-full flex gap-x-2">
            <Input label="Senha" type="password" placeholder="Senha" v-model="state.password.password" :validation="v$.password.password"/>
            <Input label="Senha (confirmação)" placeholder="Senha" type="password" v-model="state.password.confirm" :validation="v$.password.confirm"/>
        </div>
        <Select :list="['Gerente', 'Informante']" v-model="state.role" placeholder="Selecione um cargo" label="Cargo" />
        <Autocomplete :list="empresasList" v-model="state.empresa" placeholder="Selecione a empresa" label="Empresa" :validation="v$.empresa"/>
        <p class="col-span-2 text-sm text-blue-700">Ao finalizar o cadastro, você concorda com todos os <strong>Termos de Uso</strong> do serviço</p>
        <button type="submit" class="button-primary" :disabled="loading || v$.$invalid">
            <div v-if="loading" class="h-12 w-12 mx-auto">
                <SpinnerLoader/>
            </div>
            <span v-else>Finalizar</span>
        </button>
        <button type="button" class="w-full bg-transparent rounded-md text-center text-blue-500 duration-500 transform hover:scale-105" @click="changeComponent">Já possui cadastro? Faça o login</button>

    </form>
</template>

<script>
import Input from '@/components/Design/Input.vue'
import Select from '@/components/Design/Select.vue'
import Autocomplete from '@/components/Design/Autocomplete.vue'
import { reactive, ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import useApi from '@/composables/useApi'
import { useFindId } from '@/composables/useGetData'
import useNotification from '@/composables/useNotification'
import { GET_CSRF_TOKEN, POST_USER, GET_EMPRESA } from '@/composables/api/api.js'
import { nomeRule, senhaCadastroRule, phoneRule, emailRule } from '@/config/rules.js'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import SpinnerLoader from '../Design/SpinnerLoader.vue'


export default {
    name: 'SignIn',
    components: {
        Input,
        Select,
        Autocomplete,
        SpinnerLoader
    },
    setup (props, { emit }) {
        const loading = ref(false)
        const state = reactive({
            name: '',
            email: '',
            password: {
                password: '',
                confirm: ''
            },
            ramal: '',
            empresa: '',
            role: ''
        })
        // Validation
        const rules = computed(() => {
            return {
                name: nomeRule(),
                email: emailRule(),
                password: {
                    password: senhaCadastroRule(),
                    confirm: {
                        required:
                            helpers.withMessage('Este campo é obrigatório', required),
                        sameAs:
                            helpers.withMessage('As senhas devem ser iguais', (value) => value === state.password.password)
                    }
                },
                role: {
                    required:
                        helpers.withMessage('Este campo é obrigatório', required),
                },
                empresa: {
                    required:
                        helpers.withMessage('Este campo é obrigatório', required),
                    inList:
                        helpers.withMessage('Essa opção é inválida', (v) => empresasList.value.includes(v)),
                }
            }
        })
        const v$ = useVuelidate(rules, state)

        const register = async () => {
            loading.value = true
            const body = {
                nome: state.name,
                email: state.email,
                empresa_id: useFindId(empresas.value.responseData, state.empresa, 'razao'),
                password: state.password.password,
                senha_confirmacao: state.password.confirm,
                role: state.role,
                ativo: 0
            }
            
            await useApi(true).fetch(POST_USER(), body).then(async r => {
                emit('changeComponent')
            }).catch(e => {
                useNotification(e.response)
            })
            loading.value = false
        }

        const getEmpresas = useApi(false)
        const empresas = ref(getEmpresas)
        const empresasList = ref([])

        onMounted(async () => {
            useApi(false).fetch(GET_CSRF_TOKEN)
            await getEmpresas.fetch(GET_EMPRESA())
            empresasList.value = empresas.value.responseData.map(x => x.razao)
        })

        const changeComponent = () => {
            emit('changeComponent')
        }

        return {
            state,
            v$,
            empresasList,
            loading,
            register,
            changeComponent
        }
    }
}
</script>

<style>

</style>