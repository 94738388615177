<template>
    <nav class="h-24 bg-blue-600 px-16 items-center gap-x-10 hidden md:flex">
        <div class="w-32">
            <Logo/>
        </div>
        <div class="flex flex-1 gap-x-8">
            <div v-for="router in navRouters" :key="router" class="relative" :class="router.permission.includes(user.role.toLowerCase()) ? 'block' : 'hidden'"> <!--  -->
                <button class="focus:ring-blue-500 focus:ring-2 rounded-full text-neutral-50 select-none" @click="menuSelect = router.title" @blur="menuSelect = ''" type="button">{{router.title}}</button>
                <transition name="slide">
                    <div v-if="menuSelect === router.title" class=" bg-neutral-100 dark:bg-neutral-800 border rounded-lg dark:border-neutral-700 w-60 shadow-lg top-10 absolute py-4 px-2 z-10">
                        <span v-for="sub in router.subrouters" :key="sub" >
                            <div v-if="sub.permission.includes(user.role.toLowerCase())" class="hover:bg-neutral-200 dark:hover:bg-neutral-900 rounded-lg py-2 px-2">
                                <router-link :to="sub.path" v-slot="{ navigate }">
                                    <span @click="navigate" class="w-full h-full">
                                        <p class="text-left dark:text-neutral-50 text-blue-700">{{sub.name}}</p>
                                        <p class="text-left text-sm text-neutral-600 dark:text-neutral-400">{{sub.description}}</p>
                                    </span>
                                </router-link>
                            </div>
                        </span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="flex items-center gap-x-6">
            <p class="text-neutral-50">Olá, {{user.nome}}</p>
            <span class="relative">
                <button @click="navMenu = !navMenu" @blur="navMenu = false" class="cursor-pointer flex">
                    <div class="bg-blue-700 rounded-full w-14 h-14">
                        <span v-if="user.nome" class="h-full w-full flex text-neutral-100"><p class="m-auto text-2xl ">{{user.nome[0]}}{{user.nome.split(' ')[1][0] ? user.nome.split(' ')[1][0] : ''}}</p></span>
                    </div>
                </button>
                <transition name="slide">
                    <div v-if="navMenu" class="transform -translate-x-1/2 left-1/2 bg-neutral-100 text-neutral-700 dark:text-neutral-300 dark:bg-neutral-800 border rounded-lg dark:border-neutral-700 w-24 shadow-lg top-16 absolute py-2 z-10">
                        <button @click.stop="goToProfile()" class="dark:hover:bg-neutral-900 hover:bg-neutral-200 py-2 px-2 w-full">
                            <p class="text-center">Perfil</p>
                        </button>
                        <button @click.stop="logout()" class="dark:hover:bg-neutral-900 hover:bg-neutral-200 py-2 px-2 w-full">
                            <p class="text-center">Sair</p>
                        </button>
                    </div>
                </transition>
            </span>
        </div>
    </nav>
    <nav class="h-24 bg-blue-600 flex px-16 items-center gap-x-10 md:hidden">
        <div class="w-32">
            <Logo/>
        </div>
        <div class="flex flex-1 gap-x-8">
        </div>
        <div class="flex items-center gap-x-6">
            <span class="relative">
                <button @click="navMenu = !navMenu" @blur="navMenu = false" class="cursor-pointer flex">
                    <div class="bg-blue-700 rounded-full w-14 h-14">
                        <span v-if="user.nome" class="h-full w-full flex text-neutral-100"><p class="m-auto text-2xl">{{user.nome[0]}}{{user.nome.split(' ')[1][0] ? user.nome.split(' ')[1][0] : ''}}</p></span>
                    </div>
                </button>
                <transition name="slide">
                    <div v-if="navMenu" class="transform -translate-x-1/2 left-1/2 bg-neutral-100 text-neutral-700 dark:text-neutral-300 dark:bg-neutral-800 border rounded-lg dark:border-neutral-700 w-24 shadow-lg top-16 absolute py-2 z-10">
                        <button @click.stop="goToProfile()" class="dark:hover:bg-neutral-900 hover:bg-neutral-200 py-2 px-2 w-full">
                            <p class="text-center">Perfil</p>
                        </button>
                        <button @click.stop="logout()" class="dark:hover:bg-neutral-900 hover:bg-neutral-200 py-2 px-2 w-full">
                            <p class="text-center">Sair</p>
                        </button>
                    </div>
                </transition>
            </span>
        </div>
    </nav>
</template>

<script>
import Logo from '@/components/Design/Logo'
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onBeforeUnmount, onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import useNotification from '../../composables/useNotification'
export default {
    name: 'NavBar',
    components: {
        Logo
    },
    setup() {
        const navMenu = ref(false)
        const navRouters = ref([])
        const menuSelect = ref('')

        const store = useStore()
        const router = useRouter()
        const user = ref(store.getters['auth/user'])

        navRouters.value = [
            {
                title: 'Empreendimentos',
                subrouters: [
                    { name: 'Lista de Formulários', description: 'Visualizar todos os formulários', path: '/dashboard/empreendimentos/formularios', permission: ['admin', 'gerente', 'informante'] },
                    { name: 'Novo formulário', description: 'Preencher um novo formulário', path: '/dashboard/empreendimentos/formularios/novo', permission: ['admin', 'gerente', 'informante'] }
                ],
                permission: ['admin', 'gerente', 'informante']
            },
            {
                title: 'Empresas',
                subrouters: [
                    { name: 'Lista de Empresas', description: 'Ver todas as empresas cadastradas no sistema', path: '/dashboard/empresas', permission: ['admin'] },
                    { name: 'Nova', description: 'Criar uma nova empresa', path: '/dashboard/empresas/nova', permission: ['admin'] }
                ],
                permission: ['admin']
            },
            {
                title: 'Bairros',
                subrouters: [
                    { name: 'Lista de Bairros', description: 'Ver todos os bairros cadastrados no sistema', path: '/dashboard/bairros', permission: ['admin'] },
                    { name: 'Novo', description: 'Criar um novo bairro', path: '/dashboard/bairros/novo', permission: ['admin'] }
                ],
                permission: ['admin']
            },
            {
                title: 'Pessoas',
                subrouters: [
                    { name: 'Usuários', description: 'Mostrar todos os usuários', path: '/dashboard/usuarios', permission: ['admin', 'gerente'] },
                    { name: 'Empregados', description: 'Informar quantos empregados foram envolvidos no mês corrente', path: '/dashboard/empregados', permission: ['admin', 'gerente'] },
                ],
                permission: ['admin', 'gerente']
            },
            {
                title: 'Métricas',
                subrouters: [
                    { name: 'Relatórios', description: 'Realizar a exportação dos dados de recolha', path: '/dashboard/exportar/relatorios', permission: ['admin'] },
                    { name: 'Auditoria', description: 'Visualizar as ações realizadas dentro do sistema', path: '/dashboard/auditoria', permission: ['admin'] }
                ],
                permission: ['admin']
            }
        ]

        onMounted(() => {
            document.addEventListener('watchLocalStorage', () => {
                
                
                user.value = store.getters['auth/user']
            }, false)
        })

        onBeforeUnmount(() => {
            document.removeEventListener('watchLocalStorage', null, false)
        })

        const goToProfile = () => {
            router.push({ name: 'UsuarioProfile', params: { id: user.value.id } })
        }

        const logout = async () => {
            await store.dispatch('auth/logout').then(r => {
                useNotification(r)
                router.push('/').catch(() => {})
            })
        }

        return {
            navMenu,
            navRouters,
            menuSelect,
            user,
            goToProfile,
            logout
        }
    }
}
</script>

<style>

</style>