<template>
    <section class="bg-gradient-to-br from-blue-400 to-blue-500 w-screen h-screen flex">
        <section class="w-1/3 h-auto bg-white m-auto rounded-lg shadow border border-neutral-200 flex items-center justify-center py-10 px-20">
            <div v-if="showSuccess" class="flex flex-col items-center gap-y-2">
                <svg class="w-32 h-32 text-blue-900 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                <p class="text-3xl text-blue-900">Senha redefinida com sucesso</p>
                <p class="text text-neutral-600 mb-2">Realize o login para continuar acessando o sistema normalmente.</p>
                <button class="button-primary" @click="$router.push('/#login')">Ir para login</button>
            </div>
            <form  v-else class="w-full mx-auto flex flex-col gap-y-8" novalidate @submit.prevent="resetPassword">
                <!-- <Logo/> -->
                <span>
                    <h1 class="text-2xl text-blue-700 font-bold">Redefinir senha</h1>
                    <p class="text-neutral-600 mt-4">Confirme os dados e escolha uma nova senha</p>
                </span>
                <Input label="Email" placeholder="Email" type="email" v-model="email" :disabled="true"/>
                <Input label="Nova Senha" type="password" placeholder="Senha" v-model="state.password" :validation="v$.password"/>
                <Input label="Senha (confirmação)" placeholder="Confirme a senha" type="password" v-model="state.confirm" :validation="v$.confirm"/>
                <button type="submit" class="button-primary" :disabled="false && v$.$invalid">Redefinir</button>
            </form>
        </section>
    </section>
</template>

<script>
import axios from 'axios'
import { onMounted, ref } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Input from '@/components/Design/Input.vue'
import { computed, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { senhaCadastroRule } from '../config/rules'
import { helpers, required } from '@vuelidate/validators'
import Logo from '../components/Design/Logo.vue'
import { GET_CSRF_TOKEN, RESET_PASSWORD } from '../composables/api/api'
import useNotification from '../composables/useNotification'
import useApi from '../composables/useApi'
export default {
    name: 'ResetPassword',
    components: {
        Input,
        // Logo
    },
    setup() {
        const route = useRoute()

        const email = ref('')
        const token = ref('')
        const showSuccess = ref(false)
        const showSpinner = ref(true)
        const msg = ref('')

        onMounted(async () => {
            email.value = route.query.email
            token.value = route.query.token
            useApi(false).fetch(GET_CSRF_TOKEN)
        
        })
        const state = reactive({
            password: '',
            confirm: ''
        })

        const rules = computed(() => {
            return {
                password: senhaCadastroRule(),
                confirm: {
                    required:
                        helpers.withMessage('Este campo é obrigatório', required),
                    sameAs:
                        helpers.withMessage('As senhas devem ser iguais', (value) => value === state.password)
                }
            }
        })
        const v$ = useVuelidate(rules, state)

        const resetPassword = async () => {
            const body = {
                email: email.value,
                password: state.password,
                senha_confirmacao: state.confirm,
                token: token.value
            }
            await axios.post(RESET_PASSWORD().url, body).then(r => {
                useNotification(r)
                showSuccess.value = true

            }).catch(e => {
                useNotification(e.response)
            })
        }

        return {
            showSpinner,
            showSuccess,
            msg,
            state,
            email,
            resetPassword,
            v$
        }
    }
}
</script>

<style>
  .box-email {
    position: relative;
    margin: auto;
    top: 50%;
    transform: translateY(-55%);
    width: 60%;
    height: 60%;
    background-color: #f7f7f7;
    border-radius: 5px;
  }
  .reset-field {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }
</style>
