<template>
    <div class="w-full h-full">
		<svg @click="router.push('/dashboard')" class="fill-neutral-50 hover:scale-105 transition-all duration-300 cursor-pointer"
		xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080"><path d="M1769.6 897.2H636.8v-15.1l4.4-13.8 3.4-21.9v-14.9l11.3-21 9.3-39.2-2.5-12.1-5.3 1.5-21.8-14.8 7.6-19-6.4-9.5 4.7-27.1-1.4-4.3 21.1-6.7 3 9.5-4.1 23.7 8 11.8-4.7 11.8 16.2-4.5 8.2 40.1-11.1 46.8-10 18.7v11l-4 25.3-.5 1.6h1085.7c-.3-.7-.7-1.6-1.1-2.6l-28.3-20.5-31.1-13.5-17.8-52.4 10.3-48.7 6.5-4.8-11.1-57.1 12.3-64 55.8-98.6-7.8-24.2 6.6-13.2-4.4-16.2-14.1-6.3-1.6-77.3 12.5-38.2.8-.4-43-7.8-36.2-20.5-21-12.9-12.1-9.8v-45l-877.7 2V332h-22.2V221l922.1-2.1v56.7l2.7 2.2 19.5 12 32.4 18.4 58.1 10.6 7.4 26.5-12.6 7.3-8.7 26.5 1.2 59.5 10.8 4.8 9.4 34.6-6.3 12.7 8 24.8-58.8 103.8-10.8 56.2 12.9 66.7-11 8.1-7.3 34.5 12.7 37.3 24.3 10.5 35.5 25.7 1.2 3.1c.8 2.2 1.9 4.7 2.3 5.5 1.7 2.7 2.7 5.9 1.6 20.1l-.2 10.2zm-1.1-30.6s0 .1.1.1c0-.1 0-.1-.1-.1z" id="Camada_2"/><g id="Camada_3"><path d="M213.7 710.2H121V343.6h92.7v366.6zM487.6 343.1H587L455 709.7h-84.9L238 343.1h99.4L412 587l75.6-243.9z"/><path d="M827.3 343.1h99.4l-132 366.6h-84.9l-132-366.6h99.4L751.7 587l75.6-243.9z"/></g></svg>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
    name: 'LogoComponent',
    setup() {
        const router = useRouter()
        return {
            router
        }
    }
}
</script>
<style scoped>

</style>
