import axios from 'axios'
import Cookies from 'js-cookie'
import useEncryption from '@/composables/useEncryption.js'

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: null
    },
    getters: {
        authenticated: (state) => Cookies.get('authenticated') === 'true',
        user () {
            const decrypted = JSON.parse(useEncryption().decrypt(localStorage.getItem('user')))
            return decrypted
        }
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            Cookies.set('authenticated', value, { secure: true, sameSite: 'strict' })
        },
        SET_USER (state, data) {
            if (data === null) {
                localStorage.removeItem('user')
            } else {
                const event = new Event('watchLocalStorage')
                const encrypted = useEncryption().encrypt(JSON.stringify({ ...data }))
                localStorage.setItem('user', encrypted)
                document.dispatchEvent(event)
            }
        }
    },

    actions: {
        async login ({ dispatch }, credentials) {
            let response
            let user = null
            await axios.post('api/login', credentials).then(r => {
                response = r
                user = r.data.data
            })
            dispatch('attempt', user)
            return response
        },
        async attempt ({ commit }, user) {
            commit('SET_AUTHENTICATED', true)
            commit('SET_USER', user)
        },
        async updateUser ({ commit }, user) {
            commit('SET_USER', user)
        },
        async logout ({ commit }) {
            const r = await axios.post('/api/logout')
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null)
            return r
        }
    }

}
