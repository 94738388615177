import { ref } from '@vue/reactivity'

export function useFindId (list, obj, desc) {
    if (list && obj) {
        return list.filter(x => x[desc].toLowerCase() === obj.toLowerCase())[0].id
    }
    return null
}

export function useFindName (list, id, desc) {
    if (list && id) {
        return list.filter(x => x.id === id)[0][desc]
    }
    return null
}