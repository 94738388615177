<template>
    <section id="screen" class="w-screen overflow-x-hidden overflow-y-scroll h-screen flex flex-col">
        <Navbar/>
        <section class="h-full flex-1">
            <router-view/>
        </section>
    </section>
</template>

<script>
import Navbar from '@/components/Layout/Navbar'
import useApi from '@/composables/useApi.js'
import { GET_COMERCIAL_ADMIN, GET_BAIRRO } from '@/composables/api/api.js'
import { onMounted, ref, toRef } from '@vue/runtime-core'
export default {
    name: 'DashboardView',
    components: {
        Navbar
    },
    setup() {
        return {
        }
    }
}
</script>

<style>

</style>