import { ref } from 'vue'
import useNotification from '@/composables/useNotification'

import axios from 'axios'
const API = '/api'
const SECURE = 'secure'
const FULL = API + SECURE

function getAxiosMethod(method) {
    switch (method) {

    case 'GET':
        return axios.get
    case 'POST':
        return axios.post
    case 'PUT':
        return axios.put
    case 'PATCH':
        return axios.patch
    case 'DELETE':
        return axios.delete
    default:
        return axios.get
    }
}

export default function useApi(showNotification=true) {

    const isLoading = ref(false)
    const response = ref({})
    const responseData = ref({})
    const error = ref(null)

    const fetch = async (endpoint, body=null) => {
        error.value = null
        const axiosCall = getAxiosMethod(endpoint.method)
        isLoading.value = true
        try {
            const res = await axiosCall(endpoint.url, body)
            response.value = res
            responseData.value = res.data
            if(showNotification) {
                useNotification(res)
            }
        } catch (e) {
            error.value = e
            if(showNotification) {
                useNotification(e.response)
            }
        } finally {
            isLoading.value = false
        }
    }
    return { response, responseData, error, isLoading, fetch }
}