<template>
    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve" class="animate-spin w-full h-full antialiased">
        <path :class="`fill-${color}-50`" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" shape-rendering="optimizeQuality"> </path>
    </svg>
</template>

<script>
export default {
    name: 'SpinnerLoader',
    components: {
    },
    props: {
        color: {
            type: String,
            required: false,
            default: 'neutral'
        }
    },
}
</script>