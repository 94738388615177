<template>
    <Select 
        v-model="value"
        :label="label"
        :list="listFiltered"
        :disabled="disabled" :readOnly="false"
        :placeholder="placeholder"
        :validation="validation"/>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import Select from '@/components/Design/Select.vue'

export default {
    name: 'AutocompleteComponent',
    components: {
        Select
    },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        list: {
            type: Array,
            required: true
        },
        validation: {
            type: Object,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }

    },

    setup(props, { emit }) {

        const value = computed({
            get: () => props.modelValue,
            set: (vl) => emit('update:modelValue', vl)
        })

        const listFiltered = computed(() => {
            // if(props.list && value.value !== '') {

            //     
            // }
            if (value.value !== '' && props.list) { return props.list.filter(x => x.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(value.value.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()))  } 
            return props.list
        })

       

        return {
            value,
            listFiltered
        }
    },
}
</script>

<style scoped>

.slide-enter-from {
  opacity: 0;
  transform: translateY(-30%);
}
.slide-enter-to {
  opacity: 1;
  transform: translateY(0%);
}

.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-leave-from {
  opacity: 1;
  transform: translateY(0%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-30%);
}
</style>