<template>
    <h1 class="text-2xl text-neutral-500 text-center">Recuperar senha</h1>
    <div v-if="showSuccess" class="w-4/5 mx-auto flex flex-col items-center gap-y-2 my-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-32 w-32 mx-auto text-blue-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
        </svg>
        <p class="text-3xl text-blue-900">Email enviado com sucesso</p>
        <p class="text text-neutral-600 mb-2">Uma mensagem com as instruções para redefinição de senha foi enviada para seu email. Verifique sua caixa de entrada e de spam.</p>
        <button class="button-primary" @click="router.push('/')">Voltar para Início</button>
    </div>
    <form v-else class="w-4/5 mx-auto flex flex-col gap-y-8 mt-10" novalidate @submit.prevent="sendEmail()">
        <span>
            <p class="text-neutral-600">Será enviado para o email um link o qual através dele você poderá realizar a redefinição da sua senha. </p>
        </span>
        <div class="w-full">
            <Input label="Email" type="text" v-model="email" placeholder="Digite o email utlizado no cadastro" />
        </div>
        <div class="w-full">
            <button type="submit" class="button-primary" :disabled="email === ''">Enviar</button>
        </div>
        <button type="button" class="w-full bg-transparent rounded-md text-center text-blue-500 duration-500 transform hover:scale-105" @click="changeComponent(1)">Voltar para login</button>
    </form>
</template>

<script>
import Input from '@/components/Design/Input.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useApi from '@/composables/useApi'
import useNotification from '@/composables/useNotification'
import { GET_CSRF_TOKEN } from '@/composables/api/api.js'
import { useStore } from 'vuex'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { SEND_RESET_PASSWORD_LINK } from '../../composables/api/api'
export default {
    name: 'RecoveryPassword',
    components: {
        Input,
    },
    setup(props, {emit}) {
        const email = ref('')
        
        const router = useRouter()
        const showSuccess = ref(false)

        const sendEmail = async () => {
            const body = {
                email: email.value
            }
            await axios.post(SEND_RESET_PASSWORD_LINK().url, body).then(r => {
                useNotification(r)
                showSuccess.value = true
            }).catch(e => {
                useNotification(e.response)
            })
        }

        onMounted(() => {
            useApi(false).fetch(GET_CSRF_TOKEN)
        })

        const changeComponent = (type) => {
            if(type === 1) {
                emit('changeComponent')
            }else {
                emit('recoveryPassword')
            }
        }

        return {
            email,
            router,
            showSuccess,
            sendEmail,
            changeComponent
        }

    }
}
</script>

<style>

</style>