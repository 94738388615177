<template>
    <div class="w-screen h-screen bg-blue-50 overflow-hidden flex">
        <div class="w-1/2 h-1/2 m-auto flex flex-col gap-y-2">
            <h1 class="text-blue-600 font-bold text-9xl text-center">404</h1>
            <span class="mx-auto">
                <p class="text-neutral-800 font-bold text-2xl text-center">Página inexistente</p>
                <p class="text-neutral-800 text-lg text-center">A página que você está tentando acessar não existe</p>
            </span>
            <span class="w-1/4 mx-auto mt-4">
                <button @click="$router.go(-1)" class="button-primary">Voltar</button>
            </span>
        </div>
    </div>
</template>

<script >
export default {
    name: 'NotFound',
    methods: {
        redirect() {
            this.$router.push('/dashboard')
        }
    },
    computed: {
        role() {
            return this.$store.getters['auth/getRole']
        },
    }
}
</script>

<style>
#no-auth {
    height: 100vh;
    width: 100vw;
    background-color:#f7f7f7;
    overflow: hidden;
    position: relative;
}

.no-auth-text {
    top: 0;
    left: 0;
    margin  : auto;
    margin-top: 20vh;
    position: relative;
    width: fit-content;
    height: fit-content; 
}

.no-auth-text h1 {
    color: #db1158;
    font-size: 10rem;
}

.no-auth-text h2 {
    color: #db1158;
    font-size: 3rem;
}

.no-auth-text p {
    color:  rgb(32, 32, 32);
    font-size: 1rem;
    cursor: pointer;
}
</style>