import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vueDebounce from 'vue-debounce'
import store from './store'
import { VueQueryPlugin, VueQueryPluginOptions } from 'vue-query'

import './assets/tailwind.css'
import '@/config/axios.config.js'

import Maska from 'maska'
import money from 'v-money3'

const app = createApp(App)

const vueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
}

app.use(store)
app.use(router)
app.use(Maska)
app.use(money)
app.use(vueDebounce)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.mount('#app')
