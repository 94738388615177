<template>
    <div ref="inputComp" class="relative w-full">
        <input
            v-model="value"
            class="default-input w-full"
            :id="`${uid}-input`"
            :readonly="readOnly"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="$emit('update:modelValue', $event.target.value); v ? v.$touch() : null;"
            autocomplete="off"
            :type="type"
        />
        <span v-if="label"
            class="default-label-input" >
            {{label}}
        </span>
        <transition name="opacity">
            <span v-if="v && v.$error"
                class="text-xs ml-1 text-red-500" >
                {{v.$errors[0].$message}}
            </span>
        </transition>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { getCurrentInstance } from 'vue'
export default {
    name: 'InputComponent',
    props: {
        modelValue: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        validation: {
            type: Object,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        readOnly: {
            type: Boolean,
            default: false,
            required: false
        },
        type: {
            type: String,
            default: 'text',
            required: false
        }

    },
    setup(props, { emit }) {
        const uid = getCurrentInstance().uid

        const onFocus = ref(false)
        const showPassword = ref(false)

        // Props
        const typeD = computed(() => {
            return props.type
        })
        const labelD = computed(() => {
            return props.label
        })
        const value = computed({
            get: () => props.modelValue,
            set: (vl) =>{ emit('update:modelValue', vl)}
        })
        // const value = props.modelValue
        const v = computed(() => {
            return props.validation
        })

        // Functions
        const giveFocus = () => {
            document.getElementById(`input-${label.value}`).focus()
        }
        return {
            onFocus,
            typeD,
            showPassword,
            value,
            labelD,
            giveFocus,
            v,
            uid
        }
    },
}
</script>
<style scoped>
@import '@/assets/css/transitions.css';
</style>