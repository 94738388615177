<template>
    <section class="bg-blue-200 w-screen h-screen flex">
        <section class="w-auto h-auto bg-neutral-100 m-auto rounded-lg shadow border border-neutral-200 flex items-center justify-center py-10 px-20">
            <div v-if="showSuccess" class="flex flex-col items-center gap-y-2">
                <p class="text-3xl text-neutral-800">Cadastro Concluído</p>
                <p class="text-xl text-neutral-600">Obrigado por confirmar o email! </p>
                <p class="text text-neutral-500 -mt-2 mb-2">Agora você deve aguardar o Gerente ou o Administrador do sistema aprovar seu cadastro.</p>
                <button class="button-primary" @click="$router.push('/#login')">Voltar</button>
            </div>
            <div v-else class="flex flex-col items-center gap-y-4">
                <p class="text-3xl text-neutral-600">Ops... Algo de errado ocorreu</p>
                <button class="button-primary" @click="$router.push('/#login')">Voltar</button>
            </div>
        </section>
    </section>
</template>

<script>
import axios from 'axios'
import { onMounted, ref } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
export default {
    name: 'EmailConfirmation',
    components: {
    },
    setup() {
        const route = useRoute()

        const home = ref(null)
        const backendLink = ref(null)
        const showSuccess = ref(false)
        const showSpinner = ref(true)
        const msg = ref('')

        onMounted(async () => {
            home.value = window.location.origin
            const link = route.query.verifyLink //+ '&id=' +this.$route.query.id + '&signature=' + this.$route.query.signature
            backendLink.value = decodeURIComponent(link)
        
            if(link) {
                await axios.get(backendLink.value).then(() => {
                    showSuccess.value = true
                    showSpinner.value = false
                }).catch(error=> {
                    msg.value = error.response.data.message
                    showSpinner.value = false
                })
            }
        

        })

        return {
            showSpinner,
            showSuccess,
            msg
        }
    }
}
</script>

<style>
  .box-email {
    position: relative;
    margin: auto;
    top: 50%;
    transform: translateY(-55%);
    width: 60%;
    height: 60%;
    background-color: #f7f7f7;
    border-radius: 5px;
  }
  .reset-field {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }
</style>
