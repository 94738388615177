<template>
    <div ref="selectComp" class="relative w-full" @keydown.tab="tabKeyPressed = true" @blur.capture="handleBlur">
        <input
            ref="button"
            v-model="value"
            class="default-input w-full cursor-pointer"
            :class="optionsVisible ? 'default-select-open-menu' : ''"
            :id="`${uid}`"
            @click="toggleOptions"
            @keyup.up.down.prevent="showOptions"
            @keyup.up.prevent="selectPrevOption"
            @keyup.down.prevent="selectNextOption"
            :readonly="readOnly"
            :disabled="disabled"
            autocomplete="no"
            :placeholder="placeholder"
        />
        <span v-if="label"
            class="default-label-input" 
            :class="optionsVisible ? 'default-label-input-focus' : ''" >
            {{label}}
        </span>
        <transition name="opacity">
            <span v-if="v && v.$error"
                class="text-xs ml-1 text-red-500" >
                {{v.$errors[0].$message}}
            </span>
        </transition>
        <span :id="`${uid}-icon-span`" v-if="value" class="default-select-icon-span">
            <button :id="`${uid}-icon-button`" @click="clear" class="default-select-icon-button" :disabled="disabled">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </span>
        <transition name="slide">
            <ul
                :id="`${uid}-options-dropdown`"
                class="select-dropdown w-full z-10"
                v-if="optionsVisible"
                ref="optionsUL"
                tabindex="-1"
                role="listbox"
                :aria-labelledby="`${uid}-label`"
                :aria-activedescendant="activeDescendant"
                @focus="setupFocus"
                @keyup.up.prevent="selectPrevOption"
                @keyup.down.prevent="selectNextOption"
                @keydown="search"
                @keydown.up.down.prevent
                @keydown.enter.esc.prevent="reset"
            >
                <li
                    class="dropdown-item transition-all duration-200"
                    :class="activeOptionIndex === index ? 'text-indigo-500 font-bold' : ''"
                    v-for="(option, index) in options"
                    :key="option"
                    :id="`${uid}-option-${index}`"
                    :aria-selected="true"
                    role="option"
                    @click="handleOptionClick(option)"
                > {{ option }} </li>
                <li v-if="options.length === 0" class="dropdown-item transition-all duration-200 hover:bg-transparent"> Sem opções disponíveis </li>
            </ul>
        </transition>
    </div>
</template>

<script>
let resetKeysSoFarTimer
import { computed, ref } from '@vue/reactivity'
import { getCurrentInstance, nextTick, watch } from 'vue'

export default {
    name: 'SelectComponent',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        list: {
            type: Array,
            required: true
        },
        validation: {
            type: Object,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        readOnly: {
            type: Boolean,
            default: true,
            required: false
        }

    },

    setup(props, { emit }) {

        const uid = getCurrentInstance().uid

        /** HTML elements */
        const optionsUL = ref()
        const button = ref()
        const selectComp = ref()

        const selectedItem = ref('')

        const options = computed(() => {
            return props.list
        })

        const v = computed(() => {
            return props.validation
        })

        const value = computed({
            get: () => props.modelValue,
            set: (vl) =>{ emit('update:modelValue', vl); optionsVisible.value = true}
        })

        watch(() => value.value, (n, o) => {
            if(o !== '' && n === '' ) {
                selectedItem.value = ''
            }
        })

        const keysSoFar =  ref('')
        const tabKeyPressed =  ref(false)
        const optionsVisible =  ref(false)

        const activeOptionIndex = computed(() => {
            return options.value.findIndex(x => x === selectedItem.value)
        })

        const prevOptionIndex = computed(() => {
            const next = activeOptionIndex.value - 1
            return next >= 0 ? next : options.value.length - 1
        })
        const nextOptionIndex = computed(() => {
            const next = activeOptionIndex.value + 1
            return next <= options.value.length - 1 ? next : 0
        })

        const activeDescendant = computed(() => {
            return `${uid}-option-${activeOptionIndex.value}`
        })

        const search = (e) => {
            clearTimeout(resetKeysSoFarTimer)
            // No alphanumeric key was pressed.
            if (e.key.length > 1) return

            resetKeysSoFarTimer = setTimeout(() => {
                keysSoFar.value = ''
            }, 500)

            keysSoFar.value += e.key
            const matchingOption = options.value.find(x => x.toLowerCase().startsWith(keysSoFar.value))

            if (!matchingOption) return

            selectedItem.value = matchingOption
        }

        const handleOptionClick = (option) => {
            selectedItem.value = option
            reset()
        }

        const setupFocus = () => {
            if (selectedItem.value) return
            // value.value = options.value[0]
            selectedItem.value = options.value[0]
        }

        const scrollInList = (itemId) => {
            const selectDropDown = document.getElementById(`${uid}-options-dropdown`)
            const myElement = document.getElementById(itemId)
            const topPos = myElement.offsetTop - selectDropDown.offsetTop
            selectDropDown.scrollTo(
                {
                    top: topPos,
                    behavior: 'smooth'
                }
            )
        }

        const selectPrevOption = () => {
            // value.value = options.value[prevOptionIndex.value]
            selectedItem.value = options.value[prevOptionIndex.value]
            scrollInList(`${uid}-option-${prevOptionIndex.value}`)
        }

        const selectNextOption = () => {
            // value.value = options.value[nextOptionIndex.value]
            selectedItem.value = options.value[nextOptionIndex.value]
            scrollInList(`${uid}-option-${activeOptionIndex.value}`)
        }

        const handleFocusTrap = (e) => {
            optionsVisible.value = true
            button.value.focus()
        }

        const handleBlur = (e) => {
            if (selectComp.value.contains(e.relatedTarget)) return
            hideOptions()
        }

        const toggleOptions = () => {
            optionsVisible.value = !optionsVisible.value
        }

        const showOptions = async () => {
            optionsVisible.value = true
            await nextTick()
            optionsUL.value.focus()
        }

        const hideOptions = () => {
            optionsVisible.value = false
        }

        const reset = async () => {
            value.value = selectedItem.value
            if(v.value) {
                v.value.$touch()
            }
            hideOptions()
            await nextTick()
            button.value.focus()
        }

        const clear = () => {
            value.value = ''
            selectedItem.value = ''
        }

        return {
            selectComp,
            button,
            value,
            options,
            uid,
            activeDescendant,
            activeOptionIndex,
            optionsUL,
            setupFocus,
            selectPrevOption,
            selectNextOption,
            prevOptionIndex,
            nextOptionIndex,
            keysSoFar,
            tabKeyPressed,
            optionsVisible,
            handleFocusTrap,
            handleOptionClick,
            handleBlur,
            toggleOptions,
            showOptions,
            hideOptions,
            reset,
            search,
            clear,
            v
        }
    },
}
</script>

<style scoped>
@import '@/assets/css/transitions.css';
</style>