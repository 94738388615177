<template>
    <section class="flex flex-col overflow-x-hidden relative">
        <router-view/>
        <notification-list/>
    </section>
</template>

<script>
import NotificationList from '@/components/Notifications/NotificationList'
export default ({
    components: {
        NotificationList
    }
})
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbbbbb;
}

::-moz-selection {
    /* Code for Firefox */
    color: #f4f4f5;
    background: #6366f1;
}

::selection {
    color: #f4f4f5;
    background: #1d4ed8;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}
</style>