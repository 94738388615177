<template>
    <h1 class="text-2xl text-neutral-500 text-center">Entrar</h1>
    <form class="w-4/5 mx-auto flex flex-col gap-y-8 mt-10" novalidate @submit.prevent="signIn()">
        <div class="w-full">
            <Input label="Email" type="text" v-model="email" placeholder="Digite o email" />
        </div>
        <div class="w-full">
            <Input label="Senha" type="password" v-model="password" placeholder="Digite sua senha" />
            <button type="button" class="text-left text-sm text-blue-500 duration-500 transform hover:text-blue-900" @click="changeComponent(2)">Esqueceu a senha? </button>
        </div>
        <div class="w-full">
            <button type="submit" class="button-primary " :disabled="loading">
                <div v-if="loading" class="h-12 w-12 mx-auto">
                    <SpinnerLoader/>
                </div>
                <span v-else>Entrar</span>
            </button>
        </div>
        <button type="button" class="w-full bg-transparent rounded-md text-center text-blue-500 duration-500 transform hover:scale-105" @click="changeComponent(1)">Não possui cadastro? Registre-se</button>
    </form>
</template>

<script>
import Input from '@/components/Design/Input.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useApi from '@/composables/useApi'
import useNotification from '@/composables/useNotification'
import { GET_CSRF_TOKEN } from '@/composables/api/api.js'
import { useStore } from 'vuex'
import axios from 'axios'
import { useRouter } from 'vue-router'
import SpinnerLoader from '../Design/SpinnerLoader.vue'
export default {
    name: 'SignIn',
    components: {
        Input,
        SpinnerLoader
    },
    setup(props, {emit}) {
        const email = ref('')
        const password = ref('')

        const store = useStore()
        const router = useRouter()

        const loading = ref(false)

        const signIn = async () => {
            loading.value = true

            await axios.get('/sanctum/csrf-cookie')
            try {
                const res = await store.dispatch('auth/login', {'email': email.value, 'password': password.value})
                // useNotification(res)
                router.push('/dashboard').catch(() => {})
            }catch(e) {
                useNotification(e.response)
            }
            
            loading.value = false
        
        }

        onMounted(() => {
            useApi(false).fetch(GET_CSRF_TOKEN)
        })

        const changeComponent = (type) => {
            if(type === 1) {
                emit('changeComponent')
            }else {
                emit('recoveryPassword')
            }
        }

        return {
            email,
            password,
            loading,
            signIn,
            changeComponent
        }

    }
}
</script>

<style>

</style>