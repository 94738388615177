import { required, email, minLength, sameAs, maxLength, helpers, between, requiredIf } from '@vuelidate/validators'

const MAX_LEN_EMAIL = 32
const MAX_LEN_NAME = 32
const MAX_LEN_PASSWORD = 32

function minLengthMessage (len) {
    return `Este campo deve conter no mínimo ${len} caracteres`
}

function maxLengthMessage (len) {
    return `Este campo deve conter no máximo ${len} caracteres`
}

function requiredMessage () {
    return 'Este campo é obrigatório'
}

function fullNameMessage () {
    return 'Este campo deve conter o nome completo'
}

function emailMessage () {
    return 'Este não é um email válido'
}

function passwordConfirmationMessage () {
    return 'As senhas devem ser iguais'
}

function specialCharacterMessage () {
    return 'A senha deve conter ao menos um caractere especial'
}

function oneUpperCharacterMessage () {
    return 'A senha deve conter ao menos um caractere maiúsculo'
}

function oneLowerCharacterMessage () {
    return 'A senha deve conter ao menos um caractere minúsculo'
}

function oneNumberCharacterMessage () {
    return 'A senha deve conter ao menos um número'
}

function arrayContaisValueMessage () {
    return 'Esse dado não existe'
}

const containsWhiteSpace = (value) => value.includes(' ')
const specialCharacter = (value) => /^(?=.*[!@#$%^&*])/.test(value)
const oneUpperCharacter = (value) => /^(?=.*[A-Z])/.test(value)
const oneLowerCharacter = (value) => /^(?=.*[a-z])/.test(value)
const oneNumberCharacter = (value) => /^(?=.*[0-9])/.test(value)

const includesValue = (param) => helpers.withParams({ array: param }, (value) => array.includes(value))


export function requiredRule() {
    const rule = {
        required:
            helpers.withMessage(requiredMessage(), required)
    }
    return rule
}

export function emailRule() {
    const rule = {
        required:
            helpers.withMessage(requiredMessage(), required),
        email:
            helpers.withMessage(emailMessage(), email),
    }
    return rule
}

export function phoneRule () {
    const min = minLength(14) // (##) ####-####
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage('Telefone inválido', min)
    }
    return rules
}

export function cepRule () {
    const min = minLength(9) //  #####-###
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage('CEP inválido', min)
    }
    return rules
}

export function teste() {
    const rules = {
        containsValue:
            helpers.withMessage(arrayContaisValueMessage(), (value) => value === true)
        
    }

    return rules
}

export function bairroRule(a) {
    const BAIRRO_MIN_LENGTH = 3
    const min = minLength(BAIRRO_MIN_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        
    }

    return rules
}

export function razaoRule() {
    const RAZAO_MIN_LENGTH = 3
    const RAZAO_MAX_LENGTH = 255
    const min = minLength(RAZAO_MIN_LENGTH) 
    const max = maxLength(RAZAO_MAX_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(RAZAO_MIN_LENGTH), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(RAZAO_MAX_LENGTH), max),
        contains:
            helpers.withMessage(fullNameMessage(), containsWhiteSpace)
    }

    return rules
}

export function nomeRule() {
    const NOME_MIN_LENGTH = 3
    const NOME_MAX_LENGTH = 50
    const min = minLength(NOME_MIN_LENGTH) 
    const max = maxLength(NOME_MAX_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(NOME_MIN_LENGTH), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(NOME_MAX_LENGTH), max),
        contains:
            helpers.withMessage(fullNameMessage(), containsWhiteSpace)
    }

    return rules
}

export function nomeEmpreendimentoRule() {
    const NOME_MIN_LENGTH = 3
    const NOME_MAX_LENGTH = 50
    const min = minLength(NOME_MIN_LENGTH) 
    const max = maxLength(NOME_MAX_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(NOME_MIN_LENGTH), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(NOME_MAX_LENGTH), max)
    }

    return rules
}

export function cidadeRule() {
    const CIDADE_MIN_LENGTH = 3
    const CIDADE_MAX_LENGTH = 50
    const min = minLength(CIDADE_MIN_LENGTH) 
    const max = maxLength(CIDADE_MAX_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(CIDADE_MIN_LENGTH), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(CIDADE_MAX_LENGTH), max)
    }

    return rules
}

export function enderecoRule() {
    const ENDERECO_MIN_LENGTH = 3
    const ENDERECO_MAX_LENGTH = 120
    const min = minLength(ENDERECO_MIN_LENGTH) 
    const max = maxLength(ENDERECO_MAX_LENGTH) 
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(ENDERECO_MIN_LENGTH), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(ENDERECO_MAX_LENGTH), max)
    }

    return rules
}

export function senhaCadastroRule () {
    const min = minLength(8)
    const max = maxLength(20)
    const rules = {
        required:
            helpers.withMessage(requiredMessage(), required),
        minLength:
            helpers.withMessage(minLengthMessage(min.$params.min), min),
        maxLength:
            helpers.withMessage(maxLengthMessage(max.$params.max), max),
        specialCharacter:
            helpers.withMessage(specialCharacterMessage(), specialCharacter),
        oneUpperCharacter:
            helpers.withMessage(oneUpperCharacterMessage(), oneUpperCharacter),
        oneLowerCharacter:
            helpers.withMessage(oneLowerCharacterMessage(), oneLowerCharacter),
        oneNumberCharacter:
            helpers.withMessage(oneNumberCharacterMessage(), oneNumberCharacter)
    }
    return rules
}
