import store from '../store'

export default function useNotification (response) {
    let message = ''
    let type = 'success'
    
    if (response.status >= 400) {
        type = 'error'
        if(response.status === 429) {
            message = [['Número máximo de requisições excedida. Aguarde alguns segundos.']]
        } else {
            message = response.data.errors ? response.data.errors : [[response.data.message]]
        }
    } else if (response.status >= 200) {
        type = 'success'
        message = response.data.message ? response.data.message : 'Sucesso'
    }
    store.dispatch('notify/addNotification', { type: type, message: message }, { root: true })
}