import axios from 'axios'
import Cookies from 'js-cookie'
import router from '../router'

axios.defaults.withCredentials = true

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:8000'
} else { 
    axios.defaults.baseURL = 'https://api.ivvbrasil.com.br'
}

axios.interceptors.response.use(response => response, error => {
    if (error.response.status == 401 && error.response.config.url !== 'api/login') {
        Cookies.set('authenticated', false, { secure: true, sameSite: 'strict' })
        router.push('/')

        return Promise.reject()
    }
    return Promise.reject(error)
})
