import { computed } from '@vue/reactivity'
import axios from 'axios'
import { reactive, unref } from 'vue'
import { useQuery } from 'vue-query'
import useNotification from '../useNotification'

const API = '/api'
const SECURE = 'secure'
const FULL = API

export const useParamBuilder = (params) => {
    return computed(() => {
        const search = new URLSearchParams()
        const obj = params // unwrap in case if it's Ref
        const entries = Object.entries(obj)
            .filter((e) => e[1] != null)
            .filter((e) => (typeof e[1] == 'string' ? e[1].length > 0 : true))
            .sort(([a], [b]) => (a > b ? 1 : a < b ? -1 : 0))
        entries.map(([key, value]) => search.append(key, value.toString()))
        return search
    })
}

export const GET_CSRF_TOKEN = {
    url: '/sanctum/csrf-cookie',
    method: 'GET',
}
export const GET_ITEM = () => {
    return {
        url: FULL + '/itens',
        method: 'GET',
    }
}

// =========================== COMERCIAL ===========================
/*
Permission: Admin
Params: Page size
Action: Returns all forms of all companies
*/
export const GET_COMERCIAL_ADMIN = (pageSize) => {
    return {
        url: FULL + `/comercial/page/${pageSize}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns all forms of requesting user's company in a period of time
*/
export const GET_COMERCIAL_BY_ANOMES = (anoMes, pageSize, filters = null) => {
    if (anoMes) {
        const query = filters
            ? '?' + useParamBuilder(filters).value.toString()
            : ''
        return {
            url: FULL + `/comercial/pesquisa/${anoMes}/${pageSize}${query}`,
            method: 'GET',
        }
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns a forms by id
*/
export const GET_COMERCIAL_BY_ID = (id) => {
    return {
        url: FULL + `/comercial/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano and url queries
Action: Returns a report of form filtered by query
*/
export const GET_REPORT = async (tipo, anomes, filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters).value.toString() : ''
    return await axios.get(FULL + `/reports/export/${tipo}/${anomes}${query}`, {responseType: 'blob'})
}

/*
Permission: Admin
Params: id, body
Action: Updates form by id
*/
export const PUT_COMERCIAL_BY_ID = async (id, body) => {
    return await axios.put(FULL + `/comercial/${id}`, body)
}

/*
Permission: Admin
Params: body
Action: Creates a new form
*/
export const POST_COMERCIAL = async (body) => {
    return await axios.post(FULL + '/comercial', body)
}

/*
Permission: Admin
Params: id
Action: Deletes a form by id
*/
export const DELETE_COMERCIAL = async (id) => {
    return await axios.delete(FULL + `/comercial/${id}`)
}

// =========================== RESIDENCIAL ===========================
/*
Permission: Admin
Params: Page size
Action: Returns all forms of all companies
*/
export const GET_RESIDENCIAL_ADMIN = (pageSize) => {
    return {
        url: FULL + `/residencial/page/${pageSize}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns all forms of requesting user's company in a period of time
*/
export const GET_RESIDENCIAL_BY_ANOMES = (anoMes, pageSize, filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters).value.toString() : ''

    return {
        url: FULL + `/residencial/pesquisa/${anoMes}/${pageSize}${query}`,
        method: 'GET',
    }
}

export const getFormularioByAnoMes = async (filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters.value.query).value.toString() : ''
    let r = null
    try {
        if(filters.value.params.tipo.value === 'comercial') {
            r = await axios.get(FULL + `/comercial/pesquisa/${filters.value.params.ano_mes}${query}`)
        }else if(filters.value.params.tipo.value === 'residencial') {
            r = await axios.get(FULL + `/residencial/pesquisa/${filters.value.params.ano_mes}${query}`)
        }else {
            r = await axios.get(FULL + `/lotes/pesquisa/${filters.value.params.ano_mes}${query}`)
        }
    } catch (e) {
        useNotification(e.response)
    }
    return r.data
}
export const useGetFormularioByAnoMes = (filters = null) => {
    return reactive(useQuery(['residencial', filters], () => getFormularioByAnoMes(filters)))

}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns a forms by id
*/
export const GET_RESIDENCIAL_BY_ID = (id) => {
    return {
        url: FULL + `/residencial/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano and url queries
Action: Returns a report of form filtered by query
*/
export const GET_RESIDENCIAL_REPORT = (ano) => {
    return {
        url: FULL + `/residencial/report/${ano}`,
        method: 'GET',
    }
}

/*
Permission: Admin
Params: id, body
Action: Updates form by id
*/
export const PUT_RESIDENCIAL_BY_ID = async (id, body) => {
    return await axios.put(FULL + `/residencial/${id}`, body)
}

/*
Permission: Admin
Params: body
Action: Creates a new form
*/
export const POST_RESIDENCIAL = async (body) => {
    return await axios.post(FULL + '/residencial', body)
}

/*
Permission: Admin
Params: id
Action: Deletes a form by id
*/
export const DELETE_RESIDENCIAL = async (id) => {
    return await axios.delete(FULL + `/residencial/${id}`)
}

// =========================== LOTE ===========================
/*
Permission: Admin
Params: Page size
Action: Returns all forms of all companies
*/
export const GET_LOTE_ADMIN = (pageSize) => {
    return {
        url: FULL + `/lotes/page/${pageSize}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns all forms of requesting user's company in a period of time
*/
export const GET_LOTE_BY_ANOMES = (anoMes, pageSize, query = '') => {
    if (anoMes) {
        const url = query === '' ? '' : `?${query}`
        return {
            url: FULL + `/lotes/pesquisa/${anoMes}/${pageSize}${url}`,
            method: 'GET',
        }
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano mes
Action: Returns a forms by id
*/
export const GET_LOTE_BY_ID = (id) => {
    return {
        url: FULL + `/lotes/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: Ano and url queries
Action: Returns a report of form filtered by query
*/
export const GET_LOTE_REPORT = (ano) => {
    return {
        url: FULL + `/lotes/report/${ano}`,
        method: 'GET',
    }
}

/*
Permission: Admin
Params: id, body
Action: Updates form by id
*/
export const PUT_LOTE_BY_ID = async (id, body) => {
    return await axios.put(FULL + `/lotes/${id}`, body)
}

/*
Permission: Admin
Params: body
Action: Creates a new form
*/
export const POST_LOTE = async (body) => {
    return await axios.post(FULL + '/lotes', body)
}

/*
Permission: Admin
Params: id
Action: Deletes a form by id
*/
export const DELETE_LOTE = async (id) => {
    return await axios.delete(FULL + `/lotes/${id}`)
}

//* =========================== BAIRRO ===========================,
/*
Permission: Admin, Gerente, Informante
Params: id
Action: Returns neighborhood by id
*/
export const GET_BAIRROS_BY_ID = (id) => {
    return {
        url: FULL + `/bairros/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: none
Action: Returns all neighborhood
*/
export const GET_BAIRROS = () => {
    return {
        url: FULL + '/bairros',
        method: 'GET',
    }
}

export const getBairros = async () => {
    const r = await axios.get(FULL + '/bairros')
    return r.data
}

export const useGetBairros = () => {
    return reactive(useQuery('bairro', getBairros))
}

/*
Permission: Admin
Params: id, body
Action: Updates neighborhood by id
*/
export const PUT_BAIRRO_BY_ID = (id, body) => {
    return {
        url: FULL + `/bairros/${id}`,
        body,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: body
Action: Creates a new neighborhood
*/
export const POST_BAIRRO = (body) => {
    return {
        url: FULL + '/bairros',
        body,
        method: 'POST',
    }
}

/*
Permission: Admin
Params: id
Action: Deletes a neighborhood by id
*/
export const DELETE_BAIRRO = (id) => {
    return {
        url: FULL + `/bairros/${id}`,
        method: 'DELETE',
    }
}

//* =========================== ESTAGIO OBRAS ===========================,
/*
Permission: Admin, Gerente, Informante
Params: id
Action: Returns building state by id
*/
export const GET_ESTAGIOOBRAS_BY_ID = (id) => {
    return {
        url: FULL + `/estagioobras/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: none
Action: Returns all building state
*/
export const GET_ESTAGIOOBRAS = () => {
    return {
        url: FULL + '/estagioobras',
        method: 'GET',
    }
}

/*
Permission: Admin
Params: id
Action: Updates building state by id
*/
export const PUT_ESTAGIOOBRAS_BY_ID = (id, body) => {
    return {
        url: FULL + `/estagioobras/${id}`,
        body,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: body
Action: Creates a new building state
*/
export const POST_ESTAGIOOBRAS = (body) => {
    return {
        url: FULL + '/estagioobras',
        body,
        method: 'POST',
    }
}

/*
Permission: Admin
Params: id
Action: Deletes a building state by id
*/
export const DELETE_ESTAGIOOBRAS = (id) => {
    return {
        url: FULL + `/estagioobras/${id}`,
        method: 'DELETE',
    }
}

//* =========================== ORIGEM RECURSO ===========================,
/*
Permission: Admin, Gerente, Informante
Params: id
Action: Returns resource origin by id
*/
export const GET_ORIGEMRECURSO_BY_ID = (id) => {
    return {
        url: FULL + `/origemrecursos/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: none
Action: Returns all resource origin
*/
export const GET_ORIGEMRECURSO = () => {
    return {
        url: FULL + '/origemrecursos',
        method: 'GET',
    }
}

/*
Permission: Admin
Params: id, body
Action: Updates resource origin by id
*/
export const PUT_ORIGEMRECURSO_BY_ID = (id, body) => {
    return {
        url: FULL + `/origemrecursos/${id}`,
        body,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: body
Action: Creates a new resource origin
*/
export const POST_ORIGEMRECURSO = (body) => {
    return {
        url: FULL + '/origemrecursos',
        body,
        method: 'POST',
    }
}

/*
Permission: Admin
Params: id
Action: Deletes a resource origin by id
*/
export const DELETE_ORIGEMRECURSO = (id) => {
    return {
        url: FULL + `/origemrecursos/${id}`,
        method: 'DELETE',
    }
}
// =========================== USER ===========================
/*
Permission: Admin
Params: Page Size
Action: Returns all users
*/
export const GET_USER = (pageSize, filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters).value.toString() : ''
    return {
        url: FULL + `/users/page/${pageSize}${query}`,
        method: 'GET',
    }
}

export const GET_USER_BY_ID = (id) => {
    return {
        url: FULL + `/users/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente
Params: none
Action: Returns all users of requesting user's company
*/
export const GET_USER_BY_EMPRESA = () => {
    return {
        url: FULL + '/users/empresa',
        method: 'GET',
    }
}


/*
Permission: Admin, Gerente, Informante
Params: body
Action: Updates requesting user data itself
*/
export const PUT_USER_BY_ITSELF = (body) => {
    return {
        url: FULL + '/users',
        body,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: id, body
Action: Updates a user by id
*/
export const PUT_USER_BY_ID = (id) => {
    return {
        url: FULL + `/users/${id}`,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: id
Action: Deletes a user by id
*/
export const DELETE_USER_BY_ID = (id) => {
    return {
        url: FULL + `/users/${id}`,
        method: 'DELETE',
    }
}
export const APPROVE_USER_BY_ID = (id) => {
    return {
        url: FULL + `/aprovacoes/${id}`,
        method: 'PUT',
    }
}
// =========================== EMPRESA ===========================
/*
Permission: Admin
Params: pageSize
Action: Returns a company by page size
*/
export const GET_EMPRESA_ADMIN = (pageSize, filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters).value.toString() : ''
    return {
        url: FULL + `/empresas/page/${pageSize}${query}`,
        method: 'GET',
    }
}

/*
Permission: Admin, Gerente, Informante
Params: id
Action: Returns a company by id
*/
export const GET_EMPRESA_BY_ID = (id) => {
    return {
        url: FULL + `/empresas/${id}`,
        method: 'GET',
    }
}

/*
Permission: Admin
Params: body
Action: Creates a company by id
*/
export const POST_EMPRESA = (body) => {
    return {
        url: FULL + '/empresas',
        body,
        method: 'POST',
    }
}

/*
Permission: Admin
Params: id, body
Action: Updates a company by id
*/
export const PUT_EMPRESA_BY_ID = (id, body) => {
    return {
        url: FULL + `/empresas/${id}`,
        body,
        method: 'PUT',
    }
}

/*
Permission: Admin
Params: id
Action: Deletes a company by id
*/
export const DELETE_EMPRESA_BY_ID = (id) => {
    return {
        url: FULL + `/empresas/${id}`,
        method: 'DELETE',
    }
}
// =========================== EMPREGADOS ===========================
/*
Permission: Admin, Gerente, Informante
Params: anomes
Action: Returns a employer by anomes
*/
export const GET_EMPREGADOS_BY_ANOMES = async (anomes, empresa_id) => {
    return await axios.get(FULL + `/empregados/pesquisa/${anomes}?empresa_id=${empresa_id}`)
}

/*
Permission: Admin
Params: pageSize
Action: Returns all employers of all companies
*/
export const GET_EMPREGADOS_ADMIN = async (pageSize) => {
    return await axios.get(FULL + `/empregados/page/${pageSize}`)
}

/*
Permission: Admin
Params: body
Action: Creates a employer by id
*/
export const POST_EMPREGADOS = async (body) => {
    return await axios.post(FULL + '/empregados', body)
}

/*
Permission: Admin
Params: empresa_id, anomes, body
Action: Updates a employer by empresa_id and anomes
*/
export const PUT_EMPREGADOS_BY_ID = async (empresa_id, anomes, body) => {
    return await axios.put(FULL + `/empregados/${empresa_id}/${anomes}`, body)
}

/*
Permission: Admin
Params: empresa_id, anomes
Action: Deletes a employer by empresa_id and anomes
*/
export const DELETE_EMPREGADOS_BY_ID = (empresa_id, anomes) => {
    return {
        url: FULL + `/empregados/${empresa_id}/${anomes}`,
        method: 'DELETE',
    }
}
// =========================== AUDIT ===========================
/*
Permission: Admin
Params: pageSize
Action: Returns the audit registers
*/
export const GET_AUDITORIA = (pageSize, filters = null) => {
    const query = filters ? '?' + useParamBuilder(filters).value.toString() : ''
    return {
        url: FULL + `/auditorias/page/${pageSize}${query}`,
        method: 'GET',
    }
}

// =========================== UNAUTHENTICATED ===========================
/*
Permission: None
Params: body
Action: Creates a new user
*/
export const POST_USER = (body) => {
    return {
        url: FULL + '/home/users',
        method: 'POST',
    }
}

/*
Permission: None
Params: none
Action: Returns all companies
*/
export const GET_EMPRESA = () => {
    return {
        url: FULL + '/home/empresas',
        method: 'GET',
    }
}

export const getEmpresa = async () => {
    const r = await axios.get(FULL + '/home/empresas')
    return r.data
}

export const useGetEmpresa = () => {
    return reactive(useQuery('empresa', getEmpresa))
}

/*
Permission: None
Params: none
Action: Returns all companies
*/
export const SEND_RESET_PASSWORD_LINK = (body) => {
    return {
        url: FULL + '/home/forgot',
        body,
        method: 'POST',
    }
}
export const RESET_PASSWORD = (body) => {
    return {
        url: FULL + '/home/password/reset',
        body,
        method: 'POST',
    }
}
/**
 * 
 * 
 */
export const getDashboard = async (anoMes) => {
    return await axios.get(FULL + `/dashboard/${anoMes}`)
}
export const useGetDashboard = (anoMes = null) => {
    return reactive(useQuery(['dash', anoMes], () => getDashboard(anoMes)))

}

export const VERIFICA_FORMULARIO_RESIDENCIAL = async (body) => {
    return await axios.post(FULL + '/residencial/verifyData', body)
}

export const VERIFICA_FORMULARIO_COMERCIAL = async (body) => {
    return await axios.post(FULL + '/comercial/verifyData', body)
}

export const VERIFICA_FORMULARIO_LOTE = async (body) => {
    return await axios.post(FULL + '/lotes/verifyData', body)
}