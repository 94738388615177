<template>
    <transition name="fade2">
    <div v-if="show" 
        :class="notification.type === 'success' ? 'border-green-600' : notification.type === 'error' ? 'border-red-600' : 'border-yellow-500'"
        class="relative border-l-4 bg-gray-100 text-gray-800 dark:text-gray-200 shadow-lg rounded-md h-auto mb-2" role="alert">
        <div class="absolute left-full -ml-4">
            <span @click.prevent="close()" aria-hidden="true" class="cursor-pointer">&times;</span>
        </div>
        <div class="flex px-4 py-2 flex-row items-center h-full gap-x-8">
            <div class="h-full  flex items-center">
                <span v-if="notification.type === 'success'" class="text-3xl material-icons-outlined select-none text-green-600">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </span>
                <span v-else-if="notification.type === 'error'" class="text-3xl material-icons-outlined select-none text-red-600">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </span>
                <span v-else class="text-3xl material-icons-outlined select-none text-yellow-500">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                </span>
            </div>
            <div class=" flex-1 ">
                <ul v-if="notification.type === 'error'" >
                    <ul v-for="msg in notification.message" :key="msg">
                        <li class="text-sm" v-for="m in msg" :key="m">{{m}}</li>
                    </ul>
                </ul>
                <span v-else>
                    <p :class="notification.type === 'success' ? 'dark:text-green-50 text-green-600' : notification.type === 'error' ? 'dark:text-red-50 text-red-500' : 'dark:text-yellow-50 text-yellow-400' " 
                    class="w-full mt-1 break-words">{{notification.message}}</p>
                </span>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
import { useStore } from 'vuex'
import { onBeforeUnmount } from 'vue'
export default {
    /* eslint-disable */
    props: ['notification', 'show'],
    setup(props) {
        const store = useStore()
        const close = () => {
            props.show = !props.show
            store.dispatch('notify/removeNotification', props.notification)
        }

        const timeout = setTimeout(() => {
            props.show = !props.show
            store.dispatch('notify/removeNotification', props.notification)
        }, 5000)

        onBeforeUnmount(() => {
            clearTimeout(timeout)
        })
        return  {
            close
        }

    }

}
</script>

<style>
@import '@/assets/css/transitions.css';
.fade2-enter-from {
  opacity: 0;
}
.fade2-enter-to {
  opacity: 1;
}

.fade2-enter-active {
  transition: all 1s ease-in-out;
}

.fade2-leave-active {
  transition: all 1s ease-in-out;
}

.fade2-leave-from {
  opacity: 1;
}

.fade2-leave-to {
  opacity: 0;
}


</style>