<template>
    <nav class="bg-blue-500 z-30 text-gray-50 fixed h-20 w-screen grid grid-cols-3 items-center justify-items-center justify-end md:justify-around gap-3 px-8 sm:px-32">
        <div class="w-32 fill-current text-gray-50" @click="scroll()">
            <Logo class="cursor-pointer logo"/>
        </div>
        <a class="hidden md:block" href="#sobre">Sobre</a>
        <a class="hidden md:block" href="#footer"> Grupo Opinião</a>
        <!-- <a href="#login" class="absolute right-4 md:relative h-1/2 w-20 text-center align-middle leading-10 hover:bg-blue-50 hover:text-blue-900 transition-all duration-700 rounded-lg">Entrar</a> -->
    </nav>
    <section class="bg-blue-500 w-screen h-screen flex flex-col lg:flex-row pt-20 px-2 lg:px-20 gap-x-32">
        <div class="w-4/5 lg:w-1/2 flex flex-col flex-wrap pt-8 lg:pt-36 mx-auto lg:mx-0">
            <h1 class="text-4xl lg:text-7xl font-bold text-neutral-50 text-center lg:text-left">Boas vindas ao IVV Distrito Federal</h1>
            <p class="text-base lg:text-xl text-neutral-50 text-center lg:text-left">
                O IVV Brasil é o portal de estatísticas do mercado imobiliário do Distrito Federal. O sistema é impulsionado pelo Grupo Opinião e tem como objetivo 
                gerar dados que auxiliem incorporadores na gestão e lançamento de seus empreendimentos, além de gerar indicadores de desempenho do setor apoiando 
                instituições parceiras na comunicação de informações sobre o mercado imobiliário.
            </p>

            <a href="#login" class="bg-neutral-50 w-full lg:w-1/4 mt-2 h-12 rounded-lg text-blue-500 text-xl duration-300 transition-all transform hover:scale-105 flex">
                <span class="m-auto">
                    Entrar
                </span>
            </a>
        </div>
        <div class="md:px-4 select-none w-full lg:w-1/2 px-8 pt-20">
            <img style="pointer-events: none" src="../assets/df.png"/>
        </div>
    </section>
    <section id="sobre" class="bg-neutral-50 w-screen h-screen flex flex-col lg:flex-row pt-20 px-2 lg:px-20 items-center">
        <div class="md:px-4 select-none w-1/2">
            <img class="w-3/4 mx-auto" style="pointer-events: none" src="../assets/ivv_chart.png"/>
        </div>
        <div class="w-1/2 flex flex-col">
            <h1 class="text-3xl lg:text-7xl font-bold text-blue-500 text-center lg:text-left">Índice de Velocidade de Vendas</h1>
            <p class="text-base lg:text-xl text-neutral-800">
                do mercado imobiliário do Distrito Federal é produzido mensalmente a partir de pesquisa direta conduzida pela Opinião Informação Estratégica 
                para o Sinduscon/DF e Ademi/DF. O cálculo do Indicador de Velocidade de Vendas (IVV) tem por objetivo acompanhar o desempenho da comercialização 
                dos imóveis residenciais e comerciais, especialmente a curto prazo. Os índices produzidos buscam ser instrumentos para análises conjunturais, 
                ao identificar as variações nas ofertas e nas vendas dos imóveis novos do Distrito Federal. As informações apresentadas resultam do levantamento 
                direto mensal, realizado pela Opinião Informação Estratégica, junto às construtoras e incorporadoras que atuam no DF.
            </p>
        </div>
    </section>
    <section id="login" class="bg-gradient-to-br from-blue-50 to-blue-100 w-screen h-screen flex">
        <section class="bg-white w-4/5 xl:w-1/3 m-auto rounded-lg border border-neutral-300 shadow-sm py-10">
            <h1 class="text-3xl text-blue-800 text-center font-bold uppercase">IVV Brasília</h1>
            <transition name="opacity">
                <SignIn v-if="authComponent == 1" @changeComponent="authComponent = 2" @recoveryPassword="authComponent = 3"/>
                <SignUp v-else-if="authComponent == 2" @changeComponent="authComponent = 1"/>
                <RecoveryPassword v-else @changeComponent="authComponent = 1"/>
            </transition>
        </section>
    </section>
    <footer id="footer" class="divider bg-blue-400 relative flex flex-col items-center z-20">
        <div class="bg-gray-800 py-2 md:py-20 px-8 md:px-12 relative h-full grid grid-cols-1 md:grid-cols-3 gap-4 justify-center">
            <div class="flex-col flax-wrap">
                <img src="../assets/logo-grupo.png"/>
                <h1 class="text-gray-50 text-2xl text-center">Quem somos?</h1>
                <p class="text-gray-400 text-md text-center">O que temos de melhor é o nosso time. Experiente, curioso, vibrante, conectado e apaixonado por gerar conhecimento e apoiar a jornada de nossos clientes.</p>
            </div>
            <div class="flex-col flax-wrap space-y-4">
                <h1 class="text-gray-50 text-2xl text-left">Entre em contato</h1>
                <div>
                    <p class="text-gray-300 text-xl text-left">Telefone</p>
                    <a class="text-gray-400 text-md text-left">+55 (61) 3212-3000</a>
                </div>
                <div>
                    <p class="text-gray-300 text-xl text-left">WhatsApp</p>
                    <a href="https://wa.me/5561996189783" target="_blank" class="text-gray-400 text-md text-left">+55 (61) 99618-9783</a>
                </div>
                <div class="flex-col flax-wrap space-y-2">
                    <p class="text-gray-300 text-xl text-left">Email</p>
                    <div>
                        <p class="text-gray-200 text-lg text-left">Contato Comercial</p>
                        <a href="mailto:contato@opiniao.inf.br" class="text-gray-400 text-md text-left">contato@opiniao.inf.br</a>
                    </div>
                    <div>
                        <p class="text-gray-200 text-lg text-left">Envie seu Currículo</p>
                        <a href="mailto:rh@opiniao.inf.br" class="text-gray-400 text-md text-left">rh@opiniao.inf.br</a>
                    </div>
                    <div>
                        <p class="text-gray-200 text-lg text-left">Canal de denúncia</p>
                        <a href="mailto:denuncia@opiniao.inf.br" class="text-gray-400 text-md text-left">denuncia@opiniao.inf.br</a>
                    </div>
                </div>
            </div>
            <div class="flex-col flax-wrap space-y-4">
                <h1 class="text-gray-50 text-2xl text-center">Onde estamos</h1>
                <div class="relative mx-auto">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=-15.797979051952868,%20-47.892887760446726&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                </div>
                <p class="text-gray-400 text-md text-left">
                Headquarter:<br>
                Brasília/DF<br>
                SRTVS Qd. 701 Ed. Palácio<br>
                do Rádio 1, Torre 3 - Cobertura<br>
                São Paulo/SP</p>
            </div>
        </div>
        <div class="bg-gray-900 p-2 md:bottom-0 w-full">
            <p class="text-sm text-gray-300 text-center align-middle">© Copyright 2021 Grupo Opinião Todos os direitos reservados. As diversas marcas comerciais pertencem a seus respectivos proprietários.</p>
        </div>
    </footer>
</template>

<script>
import SignIn from '@/components/Auth/SignIn.vue'
import SignUp from '@/components/Auth/SignUp.vue'
import RecoveryPassword from '@/components/Auth/RecoveryPassword.vue'
import Logo from '@/components/Design/Logo'
import { ref } from 'vue'
export default {
    name: 'LandingPageView',
    components: {
        SignIn,
        SignUp,
        RecoveryPassword,
        Logo
    },
    setup() {
        const authComponent = ref(1)
        return {
            authComponent
        }
    }
}
</script>

<style>
@import '../assets/css/transitions.css';
</style>